import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { MostAutocomplete, MostSubmitButton, MostCheckbox, MostSelect, MostTextField, } from "./components/MostComponents";
import { FileUpload } from "./components/FileUpload";
import FormDialog from "./components/FormDialog";
import useStyles from "./components/useStyles";
import { Header } from "./Header";
import { Footer } from "./Footer";
import MyAxios, { check_response } from "./MyAxios";
import { useGlobalHook } from '@devhammed/use-global-hook'

export const NewDossier = () => {
  const history = useHistory();
  const { userInfo } = useGlobalHook('userStore');
  const { control, register, handleSubmit, errors, setValue } = useForm();
  const [disabledButs, setDisabledButs] = useState(false)
  const [newDossierInfo, setNewDossierInfo] = useState({}); //pull down & C.
  const [searchele, setSearchele] = useState(false); 
  const [lastInsert, setLastInsert] = useState(null); 
  const classes = useStyles();
  const { t } = useTranslation(["translation", "dossier"]);
  const { setAlert1, setContent } = useGlobalHook('alertStore');
  const [uploadInfo, setUploadInfo] = useState(null);
  const [uploadInfoSignatures, setUploadInfoSignatures] = useState(null);
  const [action, setAction] = useState("");

  const appAlert = useCallback((text) => {
    setContent(text);
    setAlert1(true);
  }, [setContent,setAlert1])

  useEffect(() => {
    setSearchele(false);
    //let res = {};
    MyAxios.get("dossier_info").then((response) => {
      response = check_response(response);
      if  (!response.success) {
        console.error(response);
        appAlert(response.error);
        return
      }
      //console.log(response);

      let translated="";
      let rows="";
      let ar_id="";
      let custom_list = ["autore", "luogoopera", "tipoopera"]; //elenco pull down da customizzare
      custom_list.forEach((ar) => {
        console.log(ar);
        rows = [];
        ar_id = response[ar]; //array con id (che uso) e description (che ignoro)
        ar_id.forEach((r) => {
            //console.log(r);
            if (ar === "autore") {
              translated = `${r.nome} ${r.cognome} (${r.nomeinarte})`;
            } else if (ar === "tipoopera") {
              translated = r.description
            } else {
              let tipoluogo="";
              if (r.tipoluogo_id)
                tipoluogo=t("dossier:tipoluogo_id." + r.tipoluogo_id);
              translated = `${r.indirizzo},  ${r.citta}, ${r.nazione} (${tipoluogo})`;
            }
          rows.push({ "value": r.id, "label": translated });
        });
        response[ar] = rows; // sotituisco il suboggetto con quello nuovo, con righe {value: xx, label:yy}
        console.log(response[ar]);
      });
      let translate_list = ["statusopera", "fruibilitadossier", "fruibilitaopera", "tipoluogo", "tiposupporto"]; //elenco pull down da nazionalizzare
      translate_list.forEach((ar) => {
        console.log(ar);
        rows = [];
        ar_id = response[ar]; //array con id (che uso) e description (che ignoro)
        ar_id.forEach((r) => {
          // tabelle con chiave numerica
          if (ar === "fruibilitadossier") {
            translated = t("dossier:" + ar + "_id." + r.code);
          } else {
            translated = t("dossier:" + ar + "_id." + r.id);
          }
          rows.push({ "value": r.id, "label": translated });
        });
        response[ar] = rows; // sotituisco il suboggetto con quello nuovo, con righe {value: xx, label:yy}
      });
      //console.log(response);
      setNewDossierInfo(response);
      if(lastInsert && lastInsert.what === "autore" && response["autore"]) {
        response["autore"].forEach((r) => {
            if (r.value === lastInsert.id) {
                setValue('autore',r, { shouldValidate: true })
                setLastInsert(null)
            }
        })
      }
      if(lastInsert && lastInsert.what === "tipoopera" && response["tipoopera"]) {
        response["tipoopera"].forEach((r) => {
            if (r.value === lastInsert.id) {
                setValue('tipoopera_id',r)
                setLastInsert(null)
            }
        })
      }
      if(lastInsert && lastInsert.what === "luogo" && response["luogoopera"]) {
        response["luogoopera"].forEach((r) => {
            if (r.value === lastInsert.id) {
                setValue('luogoopera',r)
                setLastInsert(null)
            }
        })
      }
    })
    .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
    });
  }, [t, searchele, appAlert, lastInsert, setValue]);

  const actionChange = (e, el) => {
    console.error (JSON.stringify(el));
    setAction(el.value);
  }

  const onInsert = (what,id) => {
    setLastInsert({what:what, id:id})
  }

  const onSubmit = (vals) => {
    console.log("onSubmit: " + JSON.stringify(vals));
    console.log("onSubmit file: " + JSON.stringify(uploadInfo));
    if (!uploadInfo) {
        appAlert("File immagine non scelto")
        return
    }
    // if (!uploadInfoSignatures) {
        // appAlert("File delle firme non scelto")
        // return
    // }
    let formData = new FormData();
    console.log("onSubmit file2: " + JSON.stringify(uploadInfo[0]));
    formData.append("document_file", uploadInfo[0]);
    if (uploadInfoSignatures) 
      formData.append("signatures_file", uploadInfoSignatures[0]);
    formData.append("jpayload", JSON.stringify(vals));

    setDisabledButs(true)
    MyAxios.post("/newdossier", formData, {
      headers: {
        "Content-Type": "multipart/form-data; boundary=ZZZZZZZZZZZZZZZZZZZ",
      },
    })
      .then((response) => {
        response = check_response(response);
        // alert(JSON.stringify(response));
        //console.log(response);
        if (response.success) {
          history.push("/dossier");
        } else {
          console.error(response);
          appAlert(response.error);
          setDisabledButs(false)
        }
      })
      .catch(function (error) {
        console.error(error);
        appAlert(error.message?error.message:JSON.stringify(error));
        setDisabledButs(false)
      })
  };

  return (
    <div>
      <Header />
      {userInfo.application == "elivilla" ?  (
        <h1>{t("dossier:NewCelebrity")}</h1>
      ) : ( userInfo.application == "techne" ?  (
          <h1>{t("dossier:NewArtwork")}</h1>
      ) : ( userInfo.application == "hypnos" ?  (
          <h1>{t("dossier:NewPainting")}</h1>
        ) : (
          <h1>{t("dossier:NewDossier")}</h1>
        )
      )

      )}
      <Container component="main" maxWidth="md">
        <div className={classes.root}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate >
            {userInfo.application == "elivilla" ?  (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <MostTextField name="nomeopera" required={true} label={t("dossier:evento")} register={register({ required: true })} />
                    {errors.nomeopera && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>
                <Grid item xs={12}>
                    <MostTextField name="celebrity" required={true} label={t("dossier:celebrity")} register={register({ required: true })} />
                    {errors.nomeopera && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>
                <Grid item xs={12}>
                    <MostTextField name="year" type="number" min={1980} max={2040} defaultValue={2023} label={t("dossier:year")} register={register({ required: false })} />
                </Grid>
                <Grid item xs={12}>
                    <MostAutocomplete control={control} name="action" rules={{ required: true }} options={newDossierInfo.action} label={t("dossier:CreaNFT")+ " *"} onChange={actionChange} />
                </Grid>
              </Grid>

            ) : ( userInfo.application == "cottolengo" || userInfo.application == "hypnos"?  (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <MostTextField name="nomeopera" required={true} label={t("dossier:Luogo o personaggio")} register={register({ required: true })} />
                    {errors.nomeopera && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>
                <Grid item xs={12}>
                    <MostAutocomplete control={control} name="action" rules={{ required: true }} options={newDossierInfo.action} label={t("dossier:CreaNFT")+ " *"} onChange={actionChange} />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <MostTextField name="nomeopera" required={true} label={t("dossier:nomeopera")} register={register({ required: true })} />
                    {errors.nomeopera && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>

                <Grid item xs={12}>
                    <MostSelect name="fruibilitaopera" options={newDossierInfo.fruibilitaopera} control={control} placeholder={t("dossier:FruibilitaOpera")} />
                </Grid>
                <Grid item xs={6}>
                  <MostSelect control={control} name="luogoopera" options={newDossierInfo.luogoopera} placeholder={t("dossier:luogoopera")} />
                </Grid>
                <Grid item xs={3}>
                  <MostCheckbox register={register} control={control} name="riservatezzaluogo" default={false} label={t("dossier:riservato")} />
                </Grid>
                <Grid item xs={3}>
                  <FormDialog what="luogo" tipoluogoOptions={newDossierInfo.tipoluogo} setSearchele={setSearchele} disabledButs={disabledButs} onInsert={onInsert} control={control} />
                </Grid>
                <Grid item xs={9}>
                  <MostSelect name="statusopera" options={newDossierInfo.statusopera} control={control} placeholder={t("dossier:statusopera")} />
                </Grid>
                <Grid item id="tiposupporto_id" xs={12}>
                    <MostSelect name="tiposupporto_id" rules={{ required: true }} options={newDossierInfo.tiposupporto} control={control} placeholder={t("dossier:TipoSupporto")+" *"} />
                    {errors.tiposupporto_id && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>
                <Grid item xs={3}>
                  <MostCheckbox register={register} control={control} name="riservatezzastatus" default={false} label={t("dossier:riservato")} />
                </Grid>
                <Grid item xs={9} className="asinistra blackColor">
                   <span className="padding10">{t("dossier:Proprietario")}: {userInfo.username}</span>
                </Grid>
                <Grid item xs={3}>
                    <MostCheckbox register={register} control={control} name="riservatezzaproprietario" default={false} label={t("dossier:riservato")} />
                </Grid>
                <Grid item xs={12}>
                    <MostSelect name="fruibilitadossier" rules={{ required: true }} options={newDossierInfo.fruibilitadossier} control={control} placeholder={t("dossier:fruibilitadossier")+" *"} />
                    {errors.fruibilitadossier && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>


              </Grid>
            ))}
                {action == "" ? (
                  <div></div>
                ) : ( action == "tokenize" ? (
                <Grid item xs={12}>
                    <MostSelect control={control} name="grid_size" options={newDossierInfo.grid_size} placeholder={t("dossier:GridSize")+ " *"} />
                </Grid>
                ) : (
                <div>
                <Grid item xs={12}>
                    <MostTextField name="copies" type="number" label={t("dossier:NumeroCopie" + " *")} register={register({ required: true })} />
                </Grid>
                <Grid item xs={12}>
                    <MostSelect control={control} name="signature_position" options={newDossierInfo.signature_position} placeholder={t("dossier:SignaturePosition")+ " *"} register={register({ required: true })} />
                </Grid>
                <Grid item xs={12}>
                    <MostSelect control={control} name="signature_color" options={newDossierInfo.signature_color} placeholder={t("dossier:SignatureColor")+ " *"} register={register({ required: true })} />
                </Grid>
                <Grid item xs={6} >
                  Specimen di firma:
                </Grid>
                <Grid item xs={6} >
                  <FileUpload setUploadInfo={setUploadInfoSignatures} />
                </Grid>
                </div>
                )) }

              <Grid container spacing={1} alignItems="center">
                <Grid item xs={9}>
                  <MostSelect control={control} name="autore" rules={{ required: true }} options={newDossierInfo.autore} placeholder={t("dossier:autore")+" *"} />
                  {errors.autore && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>
                <Grid item xs={3}>
                  <FormDialog what="autore" setSearchele={setSearchele} disabledButs={disabledButs} onInsert={onInsert} className="MuiFormControl-marginDense"/>
                </Grid>

                <Grid item xs={9}>
                  <MostSelect control={control} name="tipoopera_id" rules={{ required: true }} options={newDossierInfo.tipoopera} placeholder={t("dossier:TipoOpera")+ " *"} />
                  {errors.tipoopera_id && <span className="badValue">{t("campo obbligatorio")}</span>}
                </Grid>
                <Grid item xs={3}>
                  <FormDialog what="tipoopera" setSearchele={setSearchele} disabledButs={disabledButs} onInsert={onInsert} className="MuiFormControl-marginDense"/>
                </Grid>


              </Grid>



                <Grid item xs={12}>   &nbsp;</Grid>
                <Grid item xs={12} className="top-margin-10">
                  <FileUpload setUploadInfo={setUploadInfo} className="top-margin-10" />
                </Grid>


            <MostSubmitButton disabled={disabledButs} label={t("dossier:Inserisci")} />
          </form>
        </div>
      </Container>
      <Footer />
    </div>
  );
};
